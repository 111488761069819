
import { computed, defineComponent, onBeforeUnmount, onMounted, ref, watch } from 'vue'
import { useRouter, onBeforeRouteUpdate } from 'vue-router'
import dayjs from 'dayjs'
import { Toast } from 'vant'
import * as storage from '@/utils/storage'
import { phoneConvert } from '@/utils/tuomin'
import useOpenInfo from '@/hooks/openInfo'
import { setApptitle } from '@/utils/native-app'
import { mutateFn } from '@/graphql'

export default defineComponent({
  setup () {
    const router = useRouter()
    onBeforeRouteUpdate((to, from) => {
      // 计算距离上次验证码发送时间
      const expireTime = dayjs(storage.get('captcha_expire_time'))
      const diff = dayjs().diff(expireTime, 'minute', true)
      // 如果在十分钟内 免验证 跳转其他页 超过十分钟 清空保存的时间
      if (diff < 10) {
        if (from.name === 'confirm') {
          router.push({ name: 'statement' })
        }
      } else {
        storage.remove('captcha_expire_time')
      }
    })
    const length = 6
    const code = ref('')
    const focus = ref(false)
    const disabled = ref(false)
    const codeRef = ref()
    const currentIndex = computed(() => code.value.length)
    const arrCode = computed(() => code.value.split(''))
    const userInfo: any = storage.get('userInfo')
    const { phone, areaCode } = userInfo.userInfo
    const { title, openInfo } = useOpenInfo()
    const isPiVerify = openInfo.value.isPiVerify
    watch(code, newV => {
      code.value = newV.replace(/[^\d]/g, '')
      if (newV.length >= 7) {
        disabled.value = true
        codeRef.value.blur()
      }
    })

    let timer: number | undefined
    const s = Number(storage.get('captcha_seconds'))
    const seconds = ref(s || 60)
    storage.set('captcha_seconds', seconds.value)
    const codeDisabled = ref(seconds.value < 0)

    // 手机号脱敏处理
    const phoneConvertFunc = computed(() => phoneConvert(areaCode, phone))

    const countDown = () => {
      seconds.value -= 1
      if (seconds.value < 0) {
        clearInterval(timer)
        codeDisabled.value = false
        seconds.value = 60
      }
    }
    onBeforeUnmount(() => {
      if (code.value.length === 6) {
        storage.set('captcha_seconds', 60)
      } else {
        storage.set('captcha_seconds', seconds.value)
      }
    })
    const sendCode = () => {
      console.log('点击发送')
      if (codeDisabled.value) {
        return
      }
      codeDisabled.value = true
      if (seconds.value === 60) {
        const params = {
          areaCode,
          phone
        }
        mutateFn('sendPhoneSms', { params }).then(result => {
          const { ok } = result
          if (ok) {
            Toast('验证码发送成功')
          }
        })
      }
      timer = setInterval(countDown, 1000)
    }
    // 一进入页面发送验证码 十分钟内免验证
    if (!storage.get('captcha_expire_time')) {
      sendCode()
    }

    const isDisabled = computed(() => code.value.length !== 6)
    const handleNext = () => {
      if (code.value.length < 6) {
        Toast('请填写完整的验证码')
        return
      }
      const params = {
        areaCode,
        phone,
        msgCode: code.value
      }
      mutateFn('getSmsVaild', { params }).then(result => {
        const { resBool } = result.data
        if (!resBool) {
          Toast('验证码不正确')
          // router.push('/confirm');
        } else {
          // 开始存储计算验证码过期时间
          storage.set('captcha_expire_time', dayjs())
          router.replace({ name: 'confirm' })
        }
      })
      // storage.set('captcha_expire_time', dayjs());
      // router.replace({ name: 'confirm' });
    }

    onMounted(() => {
      setApptitle(title)
    })
    return {
      length,
      currentIndex,
      code,
      arrCode,
      focus,
      sendCode,
      seconds,
      codeDisabled,
      disabled,
      phone,
      areaCode,
      isDisabled,
      handleNext,
      phoneConvertFunc,
      isPiVerify
    }
  }
})
